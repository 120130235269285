import React from 'react';
import useFetch  from 'use-http'
import isFunction from 'lodash/isFunction'
import Collapsible from 'react-collapsible';

import Box from '../../components/Box'
import Text from '../../components/Text'
import Loading from '../../components/Loading'

const Data = ({ path, children, loadingSize, loadingText, flex, width }) => {
  const { loading, error, data } = useFetch(path, null, []) // onMount
  if (error) console.error(error)
  return (
    <Box position="relative" flex={flex} width={width}>
      {error && (
        <Box py="1em">
          <Collapsible
            trigger={<Text color="danger">發生錯誤，請稍後再試。(...展開詳情)</Text>}
          >
            <Box as="pre" fontSize="0.9em">
              {error.stack}
            </Box>
          </Collapsible>
        </Box>
      )}
      {loading && (
        loadingText ? 'Loading...' : (
          <Box.FullAbsolute bg="rgba(255,255,255,0.5)" zIndex={99}>
            <Box.AbsCenter>
              <Loading color="purple" width={loadingSize} />
            </Box.AbsCenter>
          </Box.FullAbsolute>
        )
      )}
      {isFunction(children) ? children(data, loading) : children}
    </Box>
  )
};

export default Data;
